import styled from 'styled-components';

import {headerHeight} from '../../../../styles/variables';
import Paragraph from '../../../components/Paragraph';
import {xlMax, sm, md, xl} from '../../../../styles/media';
import {font, color, grid} from '../../../../styles/variables';

import ShopWindow from '../../../../assets/images/trainings/img-schaufenster--hintergrund-desktop.png';

export const StyledFullHeader = styled.header`
    position: relative;
    display: flex;
    height: ${headerHeight};
    ${xlMax`
        width: 100%;
    `};
`;

export const Wrapper = styled.div`
    flex: 0 1 auto;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background-image: url(${ShopWindow});
    ${xlMax`
        width: 100%;
        background-image: none;
        position: absolute;
    `};
`;

export const ImgWrapper = styled.div`
    width: 70%;
    height: 100%;
    background-image: url(${(props) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    ${xlMax`
        width: 100%;
        height: ${headerHeight};
    `};
`;

export const Date = styled(Paragraph)`
    font-size: ${font.size.xxsmall};
    color: ${color.grey};
`;

export const CategoryWrapper = styled.div`
    display: flex;
    ${sm`
		width: ${grid.container.sm};
        margin:0px auto;
        padding: 0rem 2rem;
	`};
    ${md`
		width: ${grid.container.md};
        margin:0px auto;
        padding: 0rem 2rem;
	`};
    ${xl`
		justify-content: flex-end;
        width: 30%;
        margin:0px;
        padding:0px;
	`};
`;

export const CategoryText = styled.span`
    font-size: ${font.size.xxsmall};
    background-color: ${color.snow};
    padding: 0.3125rem;
`;

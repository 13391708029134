import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectById as selectNewsById} from '../../model/news';

import Layout from '../layout/Layout';
import Detail from '../domains/news/Detail';

export default function NewsPage({pageContext}) {
    const {id} = pageContext;
    const newsById = useSelector(selectNewsById);
    const news = newsById[id];
    return (
        <Layout>
            <Detail news={news} />
        </Layout>
    );
}

NewsPage.propTypes = {
    pageContext: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
